.flex {
  display: flex;

  &.full-width {
    width: 100%;
  }

  &.full-height {
    height: 100%;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.gap-xxs {
    gap: var(--spacing-xxs);
  }

  &.gap-xs {
    gap: var(--spacing-xs);
  }

  &.gap-sm {
    gap: var(--spacing-sm);
  }

  &.gap-md {
    gap: var(--spacing-md);
  }

  &.gap-lg {
    gap: var(--spacing-lg);
  }

  &.justify-start {
    justify-content: start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: end;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-evenly {
    justify-content: space-evenly;
  }

  &.mb-xs {
    margin-bottom: var(--spacing-xs);
  }

  &.mb-sm {
    margin-bottom: var(--spacing-sm);
  }

  &.mb-md {
    margin-bottom: var(--spacing-md);
  }

  &.mb-lg {
    margin-bottom: var(--spacing-lg);
  }

  &.flow-column {
    flex-direction: column;
  }

  &.flow-row {
    flex-direction: row;
  }

  &.flow-row-wrap {
    flex-flow: row wrap;
  }

  > * {
    margin-bottom: 0;
  }
}
