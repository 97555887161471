.button {
  --button-color: var(--button-primary);

  border-radius: 3.5rem;
  border: 1px solid var(--button-color);
  box-sizing: border-box;
  cursor: pointer;
  font: var(--body-medium);
  font-weight: 600 !important;
  outline: none;
  padding: var(--spacing-xs) var(--spacing-sm);
  text-transform: uppercase;
  background-color: var(--button-color);
  color: var(--text-inverted);
  white-space: nowrap;

  // Sizes
  &.size--sm {
    border-radius: var(--radius-round);
    font: var(--label-small);
    padding: var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xxs)
      var(--spacing-xs);
  }

  // Variants
  &.variant-outline,
  &.variant-text {
    background-color: transparent;
    color: var(--button-color);
  }

  &.variant-text {
    border-color: transparent;
  }

  // Colors
  &.color-secondary {
    --button-color: var(--button-secondary);
  }

  &.color-tertiary {
    --button-color: var(--button-tertiary);
    color: var(--text-primary);
  }

  &.color-destructive {
    --button-color: var(--button-destructive);
  }

  &.color-inverted {
    --button-color: var(--button-inverted);
  }

  // State
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  > span {
    // TODO: max height should reflect button size
    align-items: center;
    justify-content: center;
    display: flex;
    gap: var(--spacing-xs);
    max-height: 20px;
  }

  &.blank {
    --button-color: var(--button-tertiary);

    pointer-events: none;
    background-color: var(--button-color);

    & > span {
      visibility: hidden;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .progress {
    animation: rotating 1s linear infinite;
  }
}
