@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

.phone-input-main {
  border: none;
  border-bottom: 1px solid var(--stroke-primary);
  background-color: transparent;
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  outline: none;
  text-align: center;
  caret-color: var(--text-primary);
  color: var(--text-primary);
  font-family: Avantt-Regular;
  padding-bottom: var(--spacing-xs);
  font-weight: 600;
  width: 100%;
  padding-left: 90px;

  &::placeholder {
    opacity: 0.8;
    font: var(--body-large);
  }

  &:focus {
    border-color: var(--stroke-accent);
  }
}

@media only screen and (min-width: 768px) {
}
