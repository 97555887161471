@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

.info-icon {
  margin: 0.5em;
  cursor: pointer;
}

.info-tooltip > .tooltip-inner {
  background-color: var(--bg-inverted) !important;
  color: var(--text-inverted) !important;
  text-align: left;
  border-radius: 0 !important;
  min-width: 250px;
  font: $body;
  padding: var(--spacing-xs);
  opacity: 1 !important;
}

.info-tooltip.show {
  opacity: 1 !important;
}

.info-tooltip > .bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $pale-teal !important;
}
