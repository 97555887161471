.abridge-input-root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  font: var(--body-medium);

  input {
    color: var(--text-primary);
    border: none;
    outline: none;
    padding: 0.5rem 1rem 0.5rem 0;
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid var(--stroke-secondary);
    height: 36px;

    &:focus {
      border-bottom-color: var(--brand-secondary);
    }

    &:disabled {
      opacity: 0.3;
      border-bottom: 1px solid var(--stroke-tertiary);
    }

    &::placeholder {
      opacity: 0.8;
    }

    &.has-icon {
      padding-left: var(--spacing-lg);
    }

    &.invalid {
      border-bottom-color: var(--text-error) !important;
      font-weight: 600 !important;
      color: var(--text-error) !important;
    }

    &.has-error {
      border-bottom-color: var(--text-error);
    }
  }
}

.abridge-input-label {
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-family: Avantt-Regular;
}

.prefix {
  align-items: center;
  display: flex;
  left: 0;
  position: absolute;
}

.suffix {
  align-items: center;
  display: flex;
  right: 0;
  position: absolute;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress {
  animation: rotating 1s linear infinite;
}

.error {
  position: absolute;
  bottom: -24px;
  color: var(--text-error);
}
