@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

.phone-number-country-select {
  border: none;
  outline: none;
  color: var(--text-primary);
  font-family: Avantt-Regular;
  font-weight: 600;
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
  background: none;
  width: 70px;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.phone-number-country-select::-ms-expand {
  display: none;
}

.phone-number-country-select:disabled {
  cursor: not-allowed;
  opacity: 1;
}

.phone-number-country-wrapper {
  padding-bottom: var(--spacing-xs);
  margin-right: 10px;
  white-space: nowrap;
  position: absolute;
  left: 0;
}

.phone-number-country-select-arrow {
  position: relative;
  pointer-events: none;
  vertical-align: text-top;
  width: 15px;
  height: 15px;
  right: 5px;
  margin-left: 2px;
}

@media only screen and (min-width: 768px) {
}
