@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

.sign-in-root {
  background: var(--bg-primary);
  height: 100%;
  width: 100%;
  min-height: 650px;
}

.sign-in {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  column-gap: 11em;
  align-items: center;
  padding: var(--spacing-xxxl);
  animation: fade-in 0.3s;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
}

.sign-in-bubble {
  margin: auto 0;
  display: flex;
  position: relative;
  align-items: center;
}

.verification-code-bubble {
  padding: var(--spacing-xxxl) 0 var(--spacing-lg);
}

.login-graphic {
  color: var(--icon-brand);
  position: absolute;
  left: 100%;
  width: 765px;
}

.abridge-logo-sign-in {
  width: 190px;
  height: 32px;
  color: var(--icon-logo);
}

.sign-in-email-input {
  width: 100%;
  margin-bottom: unset !important;

  & input {
    text-align: center;
    font: var(--body-large);
    border-bottom: 1px solid var(--stroke-primary);

    &::placeholder {
      opacity: 1;
      text-align: center;
    }
  }
}

.sign-in-info-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  gap: 2em;

  & > .title {
    color: var(--text-primary);
    font: var(--display-large);
    text-transform: uppercase;
    font-size: 56px;
    line-height: 56px;
  }
}

.sign-in-input-header {
  font: var(--title-small);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 115%;
  text-align: center;
  color: var(--text-primary);
}

.sign-in-input-text {
  font: var(--body-small);
  text-align: center;
  color: var(--text-primary);
  margin: var(--spacing-xs) 0 var(--spacing-lg);
}

.sign-in-error-text {
  font: var(--body-small);
}

.sign-in-error-alert {
  margin-top: 1rem;
  padding: 0.5em !important;
}

.sign-in-footer {
  font: var(--body-small);
  text-align: left;
  color: var(--text-primary);
}

.sign-in-footer > a {
  color: var(--text-accent);
  text-decoration: none;
  font-weight: 600 !important;
}

.phone-code-resend-text-message {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.phone-code-resend-link {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
}

.phone-code-resend-link-button {
  font: var(--body-medium);
  text-transform: none;
  text-decoration: underline;
  font-weight: 400 !important;
  color: var(--text-accent) !important;
  background-color: transparent;
  padding-left: 1px;
  padding-right: 1px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 8px;
}

.phone-code-resend-link-button:focus {
  outline: none;
  box-shadow: none;
}

.phone-code-resend-button {
  margin-top: 20px;
  background: var(--bg-primary);
  border: 1px solid $carbon;
  border-radius: 4px;
  font: var(--body-medium);
  color: var(--text-primary);
}

.phone-code-resend-button-img {
  margin-right: 10px;
}

.email-input-form {
  width: 100%;
}

.terms-and-conditions {
  text-align: center;
  font: var(--body-small);
  font-weight: 600;
  color: var(--text-primary);
  line-height: 21px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin: 0 -8px var(--spacing-sm);
}

.marketing-tooltip {
  text-decoration: underline;
}

.terms-and-conditions > a {
  color: var(--text-accent);
  text-decoration: none;
  font-weight: 900;
}

.sign-in-continue-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;

  > button {
    width: 180px;
  }
}

.sign-in-verification-input-error {
  color: var(--text-error);
  font: var(--body-small);
  white-space: pre-wrap;
  padding: 20px 0px 0px;
  display: inline-block;
  line-break: auto;
  text-align: justify;
}

.sign-in-phone-number-div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}

.sign-in-email-div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.sign-in-ph-num-input-error {
  color: var(--text-error);
  font: var(--body-small);
  white-space: pre-wrap;
  margin: -5px 0 20px;
  display: flex;
  line-break: auto;
  text-align: center;
  justify-content: center;
}

.sign-in-email-input-error {
  color: var(--text-error);
  font: var(--body-small);
  white-space: pre-wrap;
  margin: -5px 0 20px;
  display: flex;
  line-break: auto;
  text-align: center;
  justify-content: center;
}

.sign-in-logo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-in-send-another-code-container {
  opacity: 0;
  transition: opacity 200ms;

  &.show {
    opacity: 1;
  }
}

.sign-in-call-me-with-code-button {
  margin-top: 10px;
}

.sign-in-send-me-another-code-button {
  margin-top: 1em;
}

.hideMobile {
  display: none;
}

.hideDesktop {
  display: flex;
}

.sign-in-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 405px;
  max-width: 410px;
  width: 90vw;
}

.back-button {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--bg-primary);
  }
}

@media only screen and (min-width: 768px) {
  .sign-in-loader {
    width: 450px;
  }

  .sign-in-verification-input {
    padding: 0 30px;
  }

  .hideMobile {
    display: flex;
  }

  .hideDesktop {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .sign-in-info-main {
    width: 100%;
  }
}
