@import "~node_modules/@abridge/web-ui/scss/constants";
@import "~node_modules/@abridge/web-ui/scss/animations";

.sign-in-input-bubble {
  background: var(--bg-secondary);

  -webkit-border-top-right-radius: var(--radius-round);
  -moz-border-radius-topright: var(--radius-round);
  border-top-right-radius: var(--radius-round);

  border: 1px solid var(--stroke-primary);

  height: 480px;
  width: 400px;
  padding: var(--spacing-xxxl);
  animation: fade-in 0.3s;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .sign-in-input-bubble {
  }
}
